<template>
  <div heading :data-theme="theme">
    <slot name="prepend"></slot>
    <component :is="tag" class="title-area">
      <slot name="title">
        <!-- sub를 사용하지 않을 때에는 <heading>제목</heading> 형식으로 간결히 사용할 수 있도록 -->
        <slot></slot>
      </slot>
    </component>
    <component :is="tagSub" class="sub-area">
      <slot name="sub" />
    </component>
  </div>
</template>

<script>
export default {
  name: 'Heading',
  props: {
    tag: {
      type: String,
      default: 'h2',
    },
    tagSub: {
      type: String,
      default: 'p',
    },
    theme: {
      type: String,
      default: 'base',
      validator: t => {
        const valid = ['base', 'no-underline', 'lesson-title'].includes(t);
        if (!valid) throw Error(`heading: theme "${t}" not available`);
        return valid;
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[heading] {
  &[data-theme='base'], &[data-theme='no-underline'] {.flex;
    > .title-area {.c(@black-primary); .w(fit-content); .z(1); .fs(20); font-weight: 700; word-break: break-all;}
    > .sub-area {.fs(16); .c(#aaa); .mt(auto); display: contents;
      // margin for contents
      &:before {content: '';.w(4);}
    }
    @media (@tp-down) {
      > .title-area { .fs(16); }
      > .sub-area {.fs(12);.lh(17)}
    }
  }
  &[data-theme='no-underline'] {
    > .title-area {display: contents;}
  }
  &[data-theme='base'] {
    > .title-area {.lh(26);
      // 밑줄 효과
      background-image: linear-gradient(@gray-200, @gray-200);
      background-repeat: no-repeat;
      background-size: 100% 10px;
      background-position: bottom;
    }
  }
}

</style>
