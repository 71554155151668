<template>
  <!-- float 버튼이 있고 하단에서 튀어나오는 컨테이너. CoachDetail에 마운트 기능 구현되어 있음 -->
  <div float-pop-in-container>
    <transition name="fade">
      <div v-if="isOpen" class="blocker" />
    </transition>
    <transition name="pop-in">
      <div v-if="isOpen" class="content-area">
        <div class="title-area">
          <h2 v-if="title" class="pop-in-title">{{ title }}</h2>
          <color-button type="white" class="btn-pop-close" @click="onClose">
            <svg-x />
          </color-button>
        </div>
        <component :is="componentPop"
          v-if="!!componentPop"
          v-bind="options"
          ref="popIn"
          :is-open="isOpen"
          @open="onOpen"
          @close="onClose"
          @dispatchToFloat="onDispatchToFloat"
        />
      </div>
    </transition>
    <div class="float-area">
      <component :is="componentFloat"
        v-if="!!componentFloat"
        v-bind="optionsFloat"
        :is-open="isOpen"
        ref="float"
        @open="onOpen"
        @close="onClose"
        @dispatchToContent="onDispatchToContent"
      />
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgX from '@shared/graphics/svg-x.vue';

export default {
  name: 'FloatPopInContainer',
  components: { ColorButton, SvgX },
  data: () => ({ isOpen: false }),
  props: {
    componentPop: {},
    options: { type: Object, default: () => ({}) },
    componentFloat: {},
    optionsFloat: { type: Object, default: () => ({}) },
    title: { type: String, default: '' },
  },
  methods: {
    onClose() {
      this.$scroll.release();
      this.isOpen = false;
    },
    onOpen() {
      this.$scroll.freeze();
      this.isOpen = true;
    },
    onDispatchToFloat(methodName, args) {
      if (!this.$refs?.float) return;
      this.$refs.float[methodName](args);
    },
    onDispatchToContent(methodName, args) {
      if (!this.$refs?.popIn) return;
      this.$refs.popIn[methodName](args);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[float-pop-in-container] {position: fixed;.b(0);.w(100%);.z(@z-float-pop-in-container);

  // animations
  .pop-in-enter-active, .pop-in-leave-active { transition: transform .3s ease-in-out; }
  .pop-in-enter, .pop-in-leave-to { transform: translateY(100%); }
  //.pop-in-enter-to { transform: translateY() }

  .blocker { position: fixed; .f; .bgc(rgba(0, 0, 0, 0.7)); .lt; }
  .fade-enter-active { .tr-o(0.5s, 0.3s); }
  .fade-leave-active { .tr-o(0.5s, 0.3s); }
  .fade-enter, .fade-leave-to { .o(0) }

  .btn-pop-close {;.wh(24);.p(0);}
  .content-area {.abs;.w(100%);.bgc(@c-white);.z(1);border-top-left-radius: 20px;border-top-right-radius: 20px;
    // pc 기준
    .b(84);.p(60, 58, 33, 58);
  }
  .content-area > .title-area {.flex;}
  .float-area {position: fixed;.w(100%);.p(8, 58, 28, 58);.bgc(@c-white);.z(2);.b(0);}
  .pop-in-title { flex: 1;.fs(28);.c(@c-black-primary);.mb(34);}
  @media (@tp-down) {
    .float-area {.p(8, 28, 20, 28)}
    .content-area {.b(76);.p(40, 28, 33, 28);}
    .pop-in-title {.fs(16);.mb(19);}
  }
}
</style>
