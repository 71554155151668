<template>
  <component :is="tag" section-container :data-theme="theme">
    <slot></slot>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      type: String,
      default: 'section',
    },
    theme: {
      type: String,
      default: 'base',
      validator: t => {
        const valid = ['base', 'coach-detail'].includes(t);
        if (!valid) throw Error(`section-container: theme "${t}" not available`);
        return valid;
      },
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[section-container] {
  &[data-theme='base'] {}
  &[data-theme='coach-detail'] { .p(24);.br(8);border: solid 1px @gray-200;
    @media (@tp-down) {
      .p(16);
    }
  }
}
</style>
