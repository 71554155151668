import { render, staticRenderFns } from "./SectionContainer.vue?vue&type=template&id=311e0eca&"
import script from "./SectionContainer.vue?vue&type=script&lang=js&"
export * from "./SectionContainer.vue?vue&type=script&lang=js&"
import style0 from "./SectionContainer.vue?vue&type=style&index=0&id=311e0eca&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports