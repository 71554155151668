<template>
  <component :is="tag" drawer :data-open="value.toString()" :data-theme="theme">
    <div class="drawer-cover">
      <div class="drawer-cover-content" @click="onToggle">
        <slot name="cover"></slot>
      </div>
      <div class="drawer-button-area">
        <color-button @click="onToggle" type="white" class="button-drawer-toggle">
          <svg-arrow fill="#787781" />
        </color-button>
      </div>
    </div>
    <div class="drawer-content" :style="contentStyle">
      <slot name="content"></slot>
    </div>
  </component>
</template>
<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import SvgArrow from '@/views/graphics/svg-arrow.vue';

export default {
  components: { ColorButton, SvgArrow },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
    speedSecond: {
      type: Number,
      default: 0.5,
    },
    theme: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    contentStyle() {
      return { transitionDuration: `${this.speedSecond}s` };
    },
  },
  methods: {
    onToggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[drawer] {
  .drawer-cover {.flex;}
  .drawer-cover-content {flex: 1;cursor: pointer;}
  .drawer-content { height: auto; transition-property: max-height, margin-top; transition-timing-function: ease-in-out; overflow-y: hidden;}
  .drawer-button-area {.flex;.flex-ai(center);}
  .drawer-button-area .button-drawer-toggle { .wh(24);.p(0);.rel;}
  .drawer-button-area .button-drawer-toggle svg { transition: transform .2s ease-in-out;.abs;.lt(50%, 50%);transform: translate(-50%, -50%) rotate(270deg);}
  &[data-open='true'] {
    .drawer-content { max-height: 2000px;.mt(30)}
  }
  &[data-open='false'] {
    .drawer-content { max-height: 0px;.mt(0) }
    .drawer-button-area .button-drawer-toggle svg { transform: translate(-50%, -50%) rotate(90deg);}
  }
  &[data-theme='base'] {

  }
  &[data-theme='base-legal'] {
    &[data-open='true'] {
      .drawer-content {max-height: 446px; overflow-y: scroll;}
      @media (@tp-down) {
        .drawer-content {max-height: 330px;}
      }
    }
  }
  &[data-theme='base-no-top-margin'] {
    &[data-open='true'] {
      .drawer-content { .mt(0);}
    }
  }
}
</style>
