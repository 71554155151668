<template>
  <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M0.919239 13.3125L-4.01812e-08 12.3933L5.54038 6.85288L-5.24537e-07 1.3125L0.919238 0.393261L7.37886 6.85288L0.919239 13.3125Z" :fill="fill" fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-arrow',
  props: { fill: { type: String, default: '#2c81ff' } }
};
</script>
