import Vue from "vue";
import FloatPopInContainer from'./FloatPopInContainer.vue'
import _debounce from 'lodash/debounce'

// CoachDetail.vue(코치 상세 페이지)에서 태블릿 또는 모바일 화면용 하단 플로팅 및 팝인 메뉴
// SSR에서는 사용 불가하므로 유의할 것.

/**
 * @typedef {object} FloatPopInOptions
 * @property {Vue.Component} componentPop
 * @property {object} options
 * @property {Vue.Component} componentFloat
 * @property {object} optionsFloat
 * @property {string} title
 */

/**
 * @description
 * FloatPopIn
 * @function
 * @params {boolean} callOnResize - 화면 크기를 조절할 경우 자동으로 띄울지 여부(default:true)
 * @params {function} onResize - 화면 크기를 조절할 경우 띄워도 되는지 여부 판독하는 function(default: 모바일, 태블릿에서만 작동)
 * @params {FloatPopInOptions} options - callOnResize를 사용할 때 registerPopInContainer에 들어갈 option
 */
const floatPopIn = ({ callOnResize = true, onResize, options } = {}) => {
    return ({
        methods: {
            unregisterPopInContainer() {
                if (!this._popInContainer) return;
                this.$root.$el.removeChild(this._popInContainer.$el);
                this._popInContainer.$destroy();
                this._popInContainer = null;
            },
            registerPopInContainer({componentPop, options, componentFloat, optionsFloat, title, unregister = false}) {
                // 자동 화면 크기에 따른 자동 register상태일 경우 popInContainer를 unregister해야하는 경우인지 판독용
                if (unregister) {
                    this.unregisterPopInContainer();
                    return;
                }
                if (this._popInContainer) return;
                this._popInContainer = new (Vue.extend(FloatPopInContainer))({
                    parent: this.$root,
                    propsData: {componentPop, options, componentFloat, optionsFloat, title}
                });
                this._popInContainer.$mount();
                this.$root.$el.appendChild(this._popInContainer.$el);
            },
            // 디자인이 시스템상 breakpoint를 무시했기 때문에 matchedMedia 형식은 사용할 수 없음
            // CoachDetail.vue -> @media(max-width: 1279px) {...}
            _onResizeDefault(width = 0) {
                return width < 1280;
            },
            // 디바운스되지 않은 onResize
            // 작동 초기에는 디바운스 없이 바로 표현되야 하므로 그냥 적용
            _onResize() {
                if (TARGET_NODE) return;
                // 아이폰의 경우 스크롤도 resize 이벤트 발생.
                // 이 때 발생한 resize이벤트는 debounce로 등록 되었다가 일정 시간 후에 동작하며
                // 아래와 같은 오류를 발생시킴
                // 1. 페이지가 불러오자 마자 스크롤을 내리면 메모리에 resize이벤트가 debounce로 등록됨
                // 2. 페이지 이동된 이후에 resize이벤트가 지정된 시간(보통 0.x초) 이후에 debounce를 통해 작동함
                // 3. 다른 페이지에서 기존에 지정된 debounce가 작동하며 이전 페이지의 floatPopIn 모달을 보여줌
                // 위의 오류를 막으려면 페이지를 벗어난 시점인지를 debounce가 돌아갈 때마다 체크해야 함.
                // 따라서 라우트를 벗어날 때 이미 등록된 debounce 작동을 막으려면 별도의 플래그를 세워야 함.
                if (this._disableResize) return
                const width = window?.innerWidth || document?.documentElement?.clientWidth;
                if (!width) return;
                const needRegister = onResize ? onResize(width) : this._onResizeDefault(width);
                // this scope를 options에 사용할 수 있도록 getFloatPopInOptions 사용 가능
                // computed, props, data 아무 것으로나 제공하면 됨
                const popInOptions = this.getFloatPopInOptions ? this.getFloatPopInOptions() : options;
                if (needRegister) this.registerPopInContainer(popInOptions)
                else this.unregisterPopInContainer();
            },
            _debouncedOnResize: _debounce(function() {
                this._onResize();
            }, 500, {trailing: true})
        },
        mounted() {
            this._disableResize = false;
            if (TARGET_NODE) return;
            this._resizeListener = window.addEventListener('resize', this._debouncedOnResize.bind(this))
            if (callOnResize) this._onResize();
        },
        beforeDestroy() {
            this._disableResize = true;
            if (TARGET_NODE) return;
            if (this._resizeListener) window.removeEventListener('resize', this._resizeListener)
            if (callOnResize) this.unregisterPopInContainer();
        }
    })
}


export default floatPopIn